<template>
    <div id="financial" class="wh-container ">
      <div class="header no-print">
        <p class="title">Financeiro</p>
        <div>
          <b-button
            v-can="'FiConPag2'"
            class="wh-button"
            variant="outline-primary"
            outline
            @click="openBillToPay"
          >
            <span class="icon-box"><MinusRounded class="icon stroke" /></span>
            Nova saída
          </b-button>
          <b-button
            v-can="'FiConRec2'"
            class="wh-button"
            variant="outline-primary"
            outline
            @click="openNewBillToReceiveModal"
          >
            <span class="icon-box"><PlusBlue class="icon stroke" /></span>
            Nova entrada
          </b-button>
        </div>
      </div>
  
      <Tab
        :index="currentTabIndex"
        ref="warehouse_tabs"
        :keys="tabs"
        @onChange="onChangeTab"
      >
        <router-view 
          :onEditBillsToPay="onEditBillsToPay"
          :onEditBillsToReceive="onEditBillsToReceive"
        />
      </Tab>

      <BillsToReceiveModal
        :billToReceiveId="billToReceiveId"
        @onHide="billToReceiveId = null"
        @issueInvoice="issueInvoice"
      />
  
      <BillItemsModal
        :billId="billToReceiveId"
        @hidden="billToReceiveId = null"
      />
  
      <BillsToPayModal
        :billToPayId="billToPayId"
        @onHide="billToPayId = null"
      />

      <NewInvoiceModal />
      <ResumeInvoiceModal />
    </div>
  </template>
  
  <script>

  import { userHasPermission } from '@/utils/localStorageManager'
  import { showFeature } from '@/utils/permissionsHelp'
  
  export default {
    name: 'Financial',
    metaInfo: {
      title: 'Eyecare - Financeiro'
    },
    components: {
      BillItemsModal: () => import('@/modules/financial/modals/BillItemsModal'),
      BillsToPayModal: () => import('@/modules/financial/modals/BillsToPayModal'),
      BillsToReceiveModal: () => import('@/modules/financial/modals/BillsToReceiveModal'),
      ResumeInvoiceModal: () => import('@/modules/nfe/modals/ResumeInvoiceModal'),
      NewInvoiceModal: () => import('@/modules/nfe/modals/NewInvoiceModal'),
      MinusRounded: () => import('@/assets/icons/minus-rounded.svg'),
      PlusBlue: () => import('@/assets/icons/plus-blue.svg'),
      Tab: () => import('@/modules/financial/components/FinancialTabs.vue'),
    },
    mounted() {
      Object.keys(this.tabKeys).map(key => {
        if (key === 'FiConCred1')  {
          if (this.showFeature('Caução do paciente')) {
            this.tabs.push({
              label: this.tabKeys[key],
              route: this.getPathByName(this.tabKeys[key]),
              disabled: !userHasPermission(key),
              hidden: !userHasPermission(key)
            })
          }
        } else {
          this.tabs.push({
            label: this.tabKeys[key],
            route: this.getPathByName(this.tabKeys[key]),
            disabled: !userHasPermission(key),
            hidden: !userHasPermission(key)
          })
        }
      })

      this.handleTabIndex()
    },
    model: {
      prop: 'currentTab',
      event: 'onChange'
    },
    data() {
      return {
        tabs: [],
        currentTabName: this.getNameByPath(this.$route.path.replace(/\/financeiro\//g, "")),
        billToReceiveId: null,
        billToPayId: null,
        tabKeys: {
          'FiDash1':'Dashboard',
          'FiFlu1':'Fluxo de caixa',
          'FiConRec1':'Entradas',
          'FiConPag1':'Saídas',
          // 'FiConPlan1':'Controle de planos',
          'FiMov1': 'Movimentações',
          'FiConPag2': 'Transações',
          'FiConPlan3': 'Favorecido',
          'FiConCred1': 'Crédito do paciente',
          'Financeiro':'Configurações',
        },
      }
    },
    computed: {
    routesAllowed() {
      return this.tabs.filter(tab => !tab.disabled).map(tab => tab.route)
    },
    routeDefault() {
      return this.routesAllowed[0]
    },
    routePath() {
      return this.$route.path.replace(/\/financeiro\//g, "")
    },
    currentTabIndex() {
      return this.tabs.findIndex(tab => tab.route === this.routePath)
    },  
  },
    methods: {
      showFeature,
      onChangeTab(index) {
      const route = this.tabs[index].route
      const path = `/financeiro/${route}`

      path !== this.$route.path && this.$router.push(path)
    },
      onEditBillsToReceive(billToReceiveId, hasBillItems) {
        if (this.billToReceiveId) {
          this.billToReceiveId = null
        }
        this.billToReceiveId = billToReceiveId
        this.$bvModal.show(!hasBillItems ? 'bills-to-receive-modal' : 'bill-items-modal')
      },
      onEditBillsToPay(billToPayId) {
        if (this.billToPayId) {
          this.billToPayId = null
        }
        this.billToPayId = billToPayId
        this.$bvModal.show('bill-to-pay-modal')
      },
      getPathByName(name) {
        const routes = {
          'Dashboard': 'dashboard',
          'Fluxo de caixa': 'fluxo_de_caixa',
          'Entradas': 'contas_a_receber',
          'Saídas': 'contas_a_pagar',
          'Movimentações': 'movimentacoes',
          'Transações': 'transacoes',
          'Favorecido': 'favorecidos',
          'Crédito do paciente': 'credito_paciente',
          'Configurações': 'configuracoes',
        }
        return routes[name]
      },
      getNameByPath(route) {
        const names = {
          'dashboard' : 'Dashboard',
          'fluxo_de_caixa' : 'Fluxo de caixa',
          'contas_a_receber' : 'Entradas',
          'contas_a_pagar' : 'Saídas',
          'movimentacoes' : 'Movimentações',
          'transacoes' : 'Transações',
          'favorecidos' : 'Favorecido',
          'credito_paciente': 'Crédito do paciente',
          'configuracoes' : 'Configurações',
        }
        return names[route]
      },
      openNewBillToReceiveModal() {
        this.$bvModal.show('bills-to-receive-modal')
      },
      openBillToPay(){
        this.$bvModal.show('bill-to-pay-modal')
      },
      issueInvoice() {
        this.$bvModal.show('new-invoice-modal')
      },
      handleTabIndex() {
        if (this.routesAllowed.includes(this.routePath)) return 
        
        const route = this.routesAllowed[0]
        if (this.$route.path === `/financeiro/${route}`) return
        const index = this.tabs.findIndex(tab => tab.route === route)
        
        this.onChangeTab(index)
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .wh-container {
    height: 100%;
    padding: 30px 24px;
  
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: end;
      margin-bottom: 24px;
    }
    .title {
      font-family: 'Red Hat Display';
      font-weight: 700;
      font-size: 24px;
      color: var(--dark-blue);
    }
    .wh-button {
      margin: 0 5px;
      color: var(--blue-500);
    }
  }
  </style>
  
  